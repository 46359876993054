<!--
 * @Author: zengzewei
 * @Date: 2021-11-23 17:53:13
 * @LastEditTime: 2021-12-10 17:06:13
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \onesports-arc-view\src\views\report\appointmentStatistics\index.vue
-->
<template>
    <div class="wrap">
        <div class="search-container">
            <el-row>
                <el-col :span="20">
                    <el-row>
                        <el-form ref="form" :model="form" label-width="100px">
                            <el-col :span="12">
                                <el-form-item label="选择时间：">
                                    <el-date-picker
                                        v-model="form.time"
                                        type="daterange"
                                        :clearable="true"
                                        value-format="yyyy-MM-dd"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-form>
                    </el-row>
                </el-col>
                <el-col :span="4" class="btn">
                    <el-button  class="search-button" @click="searchEvent">{{$t("search")}}</el-button>
                    <el-button  class="clean-button" @click="cleanForm">{{$t("clean")}}</el-button>
                </el-col>
            </el-row>
            <el-row>
                <el-button @click="selectTime('today')">今天</el-button>
                <el-button @click="selectTime('week')">最近7天</el-button>
                <el-button @click="selectTime('month')">最近30天</el-button>
            </el-row>
        </div>
        <div class="table-container">
            <el-table
                :data="tableData"
                :height="tableHeight - 4"
                ref="tableData"
                style="width: 100%"
                border
                show-summary
                :summary-method="getSummaries"
                :row-class-name="tableRowClassName"
            >
                <el-table-column
					prop="date"
                    min-width="150"
                    align="center"
					label="日期">
				</el-table-column>
                <el-table-column
					prop="venueName"
                    min-width="150"
                    align="center"
					label="场馆">
				</el-table-column>
                <el-table-column
					prop="sportProject"
                    min-width="150"
                    align="center"
					label="运动项目">
				</el-table-column>
                <el-table-column
					prop="orderNum"
                    min-width="100"
                    align="center"
					label="订单数">
				</el-table-column>
            </el-table>
        </div>
        <div class="page-warp" >
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="form.pageNum"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="form.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="form.total"
                    >
                    </el-pagination>
                    <el-button class="default-button" @click="exportReservationOrder" v-if="buttonAuth.includes('venueOperation:reservationOrderStat:export')">导出数据</el-button>
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/index";
import apis from "@/apis";
import filwDownload from "@/mixins/download"

export default {
    mixins: [mixin, filwDownload],
    data() {
        return {
            form: {
                time: '',
                endDate: '',
                beginDate: '',
                pageNum: 1,
                pageSize: 10,
                total: 0,
            },
            tableData: [],
        };
    },

    methods: {
        searchData() {
            if (this.form.time && this.form.time.length > 0) {
                this.form.beginDate = this.form.time[0]
                this.form.endDate = this.form.time[1]
            } else {
                this.form.beginDate = ''
                this.form.endDate = ''
            }
            this.$http.post(apis.appointmentStatisticsList, this.form).then((res) => {
                if (res.data.code === 0) {
                    this.tableData = res.data.rows
                    this.form.total = res.data.total
                    this.pageNum = res.data.pageNum
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        search() {
            this.$http.post(apis.appointmentStatisticsList, this.form).then((res) => {
                if (res.data.code === 0) {
                    this.tableData = res.data.rows
                    this.form.total = res.data.total
                    this.pageNum = res.data.pageNum
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        cleanForm() {
            this.form = {
                time: '',
                endDate: '',
                beginDate: '',
                pageNum: 1,
                pageSize: 10,
                total: 0,
            }
            this.searchEvent();
        },
        selectTime(type) {
            const toData = new Date(new Date().toLocaleDateString()).getTime()
            const todayStart = toData //结束时间
            this.form.endDate = this.formatTimeStamp(todayStart)
            if (type === 'today') {
                this.form.beginDate = this.formatTimeStamp(todayStart)
                this.search()
            } else if (type === 'week') {
                // const todayStart = toData //结束时间
                const past7daysStart = toData - 7 * 3600 * 24 * 1000 //开始时间
                this.form.beginDate = this.formatTimeStamp(past7daysStart)
                this.search()
            } else {
                const past30daysStart = toData - 30 * 3600 * 24 * 1000 //开始时间
                this.form.beginDate = this.formatTimeStamp(past30daysStart)
                this.search()
            }
        },
        //时间戳转成时间类型
        formatTimeStamp(timestamp) {
            const now = new Date(timestamp)
            const year = now.getFullYear()
            let month = now.getMonth() + 1
            let date = now.getDate()

            if (month < 10) { month = `0${month}` }
            if (date < 10) { date = `0${date}` }

            return `${year}-${month}-${date}`
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            if (columns.length > 0) {
                columns.forEach((column, index) => {
                    if (index === 1 || index === 2) {
                        sums[index] = "-";
                    } else if (index === 0) {
                        sums[index] = "合计";
                    } else if (index === 3) {
                        const values = data.map(item => Number(item[column.property]));
                        sums[index] = values.reduce((prev, curr) => {
                            return prev + curr;
                        }, 0);
                        sums[index] = parseFloat(sums[index]).toFixed(0)
                   }
                })
            }
            return sums
        },
        exportReservationOrder() {
            this.$http.post(apis.exportReservationOrder, {
                beginDate: this.form.beginDate,
                endDate: this.form.endDate,
            }).then((res) => {
                console.log(res)
                this.exportFun(res.data.rows.url, res.data.rows.fileName)
            })
        },
    },
    updated() {
        this.$nextTick(() => {
            this.$refs.tableData.doLayout()
        })
    },
};
</script>

<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.page-warp{
    padding: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between
}
.btn{
    display: flex;
    justify-content: flex-end
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
